var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-8"},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Register a User ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" You can register another user with certain permissions ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"username"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"Username","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"username","state":errors.length > 0 ? false:null,"name":"username","placeholder":"john123"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"Email","rules":"required|email|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"First name","label-for":"firstName"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","state":errors.length > 0 ? false:null,"name":"firstName","placeholder":"John"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Last name","label-for":"lastName"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"Last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastName","state":errors.length > 0 ? false:null,"name":"lastName","placeholder":"Doe"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confrim Password","label-for":"confirmPassword"}},[_c('validation-provider',{attrs:{"mode":"lazy","name":"password","rules":"confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"confirmPassword","placeholder":"············"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('br'),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.isDisabled}},[_vm._v(" Sign up ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }