<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-8">

        <b-card-title class="mb-1">
          Register a User
        </b-card-title>
        <b-card-text class="mb-2">
          You can register another user with certain permissions
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="registerForm"
          v-slot="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Username"
                rules="required|min:3"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="username"
                  placeholder="john123"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Email"
                rules="required|email|max:64"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- First Name -->
            <b-form-group
              label="First name"
              label-for="firstName"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="First name"
                rules="required"
              >
                <b-form-input
                  id="firstName"
                  v-model="firstName"
                  :state="errors.length > 0 ? false:null"
                  name="firstName"
                  placeholder="John"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Last Name -->
            <b-form-group
              label="Last name"
              label-for="lastName"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Last name"
                rules="required"
              >
                <b-form-input
                  id="lastName"
                  v-model="lastName"
                  :state="errors.length > 0 ? false:null"
                  name="lastName"
                  placeholder="Doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm Password -->
            <b-form-group
              label="Confrim Password"
              label-for="confirmPassword"
            >
              <validation-provider
                v-slot="{ errors }"
                mode="lazy"
                name="password"
                rules="confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="confirmPassword"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="confirmPassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                I agree to
                <b-link>privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group><br>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid || isDisabled"
            >
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import {
  required, email, min, max, alpha, confirmed,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Auth } from 'aws-amplify'

export default {
  components: {

    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isDisabled: false,
      regEmail: '',
      username: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      status: '',

      // validation rules
      required,
      email,
      min,
      max,
      alpha,
      confirmed,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          this.isDisabled = true
          try {
            const userPayload = JSON.stringify({
              firstName: this.firstName,
              lastName: this.lastName,
            })
            await Auth.signUp({
              username: this.username,
              password: this.password,

              attributes: {
                email: this.regEmail,
                'custom:payload': userPayload,
                'custom:id': 'To be updated on post confirmation',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `You have successfully created an account for ${this.firstName} ${this.lastName}`,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.isDisabled = false
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                icon: 'ErrorIcon',
                text: `${error.message}`,
              },
            })
            this.isDisabled = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
